import 'letteringjs'
import 'waypoints/lib/noframework.waypoints.min'

document.addEventListener('DOMContentLoaded', () => {
  new WOW().init()
});
$(function () {
  const texts = $('.fadeInUpCustom')
  texts.textillate({
    autoStart: false,
    in: {
      effect: 'fadeInUp',
      delayScale: 1,
      delay: 2,
    },
  });
  texts.each(function () {
    const item = this

    new Waypoint({
      element: item,
      handler: function (direction) {
        if (direction === 'down' && !$(item).hasClass('fadeInUpCustom--active')) {
          $(item).addClass('fadeInUpCustom--active')
          $(item).textillate('in')
        }
      },
      offset: '100%'
    })
  });
})

