import {gsap} from 'gsap';

document.addEventListener('DOMContentLoaded', () => {
  const list = document.querySelector('.vacancies-list')
  if (list) {
    const items = list.querySelectorAll('.vacancies-list__item')
    let activeItem = null
    items?.forEach((item, index) => {
      const toggle = item.querySelector('.vacancies-list__toggle')
      const content = item.querySelector('.vacancies-list__content')
      if (toggle && content) {
        toggle.addEventListener('click', () => {
          if (activeItem) {
            activeItem.querySelector('.vacancies-list__toggle').classList.remove('vacancies-list__toggle--active')
            gsap.to(activeItem.querySelector('.vacancies-list__content'), {height: 0})
          }
          if (activeItem !== item) {
            toggle.classList.add('vacancies-list__toggle--active')
            gsap.to(content, {height: 'auto'})
          }
          if (activeItem === item) activeItem = null
          else activeItem = item
        })
      }
    })
  }
})
