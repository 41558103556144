import {gsap} from "gsap";

document.addEventListener('DOMContentLoaded', ()=> {
  const offset = (elem)=> {
    let box = elem.getBoundingClientRect();
    return {
      top: box.top + window.scrollY,
      left: box.left + window.scrollX
    };
  }

  const newCord = (e,elem, mouseout = false) => {
    let parentOffset = offset(elem)
    let relX = e.pageX - parentOffset.left
    let relY = e.pageY - parentOffset.top;
    let height = mouseout ? 0 : elem.clientWidth * 2.25
    elem.querySelector('span')?.setAttribute('style', `top: ${relY}px; left: ${relX}px; height: ${height}px `)
  }

  const buttons = document.querySelectorAll('.color-button')
  buttons?.forEach(button => {
    let tl =gsap.timeline()
    button.addEventListener('mouseover', (e)=> {
      if(button.classList.contains('color-button--blue-border')) {
        tl.clear()
        tl.to(button, {backgroundColor: '#001947',  delay: 0.2, duration: 0})
      }
      newCord(e, button)
    })
    button.addEventListener('mouseout', (e)=> {
      if(button.classList.contains('color-button--blue-border')) {
        tl.clear()
        tl.to(button, {backgroundColor: 'transparent',  delay: 0.1, duration: 0})
      }
      newCord(e, button, true)
    })
  })

})
