import {gsap} from 'gsap'

const throttle = (func, ms) => {

  let isThrottled = false,
    savedArgs,
    savedThis;

  function wrapper() {
    if (isThrottled) { // (2)
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    func.apply(this, arguments); // (1)

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}
window.scrollWidth = 0;
const getScrollWidth = throttle(() => {
  let div = document.createElement('div');
  div.style.overflowY = 'scroll';
  div.style.width = '50px';
  div.style.height = '50px';
  document.body.append(div);
  let width = div.offsetWidth - div.clientWidth;
  div.remove();
  window.scrollWidth = width
}, 1000)
getScrollWidth()

window.addEventListener('resize', getScrollWidth)
document.addEventListener('DOMContentLoaded', () => {

  const headerBurger = document.querySelector('.header-burger')
  const popupMenu = document.querySelector('.header-popup')
  const popupMenuWrapper = popupMenu?.querySelector('.header-popup__wrapper')
  const popupMenuClose = popupMenu?.querySelector('.header-popup__close')
  const duration = 0.5
  const openModal = () => {
    gsap.to(document.body, {overflowY: 'hidden', paddingRight: window.scrollWidth, duration: 0})
    gsap.to(popupMenu, {backgroundColor: 'rgba(0,0,0,.6)', pointerEvents: 'auto', duration});
    gsap.to(popupMenuWrapper, {x: 0, duration});
  }
  const closeModal = () => {
    gsap.to(document.body, {overflowY: 'auto', paddingRight: 0, duration: 0})
    gsap.to(popupMenu, {backgroundColor: 'transparent', pointerEvents: 'none', duration});
    gsap.to(popupMenuWrapper, {x: '-100%', duration});
  }
  if (headerBurger && popupMenu && popupMenuWrapper) {
    headerBurger.addEventListener('click', openModal)
    popupMenu.addEventListener('click', closeModal)
    popupMenuClose.addEventListener('click', closeModal)
    popupMenuWrapper.addEventListener('click', e => e.stopPropagation())
  }

  const searchButton = document.querySelector('.header__search')
  const searchPopup = document.querySelector('.search-popup')
  const searchClose = searchPopup?.querySelector('.search-popup__close')

  searchButton?.addEventListener('click', () => {
    let tl = gsap.timeline()
    tl.to(document.body, {overflow: 'hidden hidden', paddingRight: scrollWidth, duration: 0}, '>')
    tl.to(searchPopup, {x: '100%', duration: 0});
    tl.to(searchPopup, {x: 0, duration});
  })
  searchClose?.addEventListener('click', () => {
    let tl = gsap.timeline()
    tl.to(document.body, {overflow: 'hidden auto', paddingRight: 0, duration: 0}, '>')
    tl.to(searchPopup, {x: '100%', duration});
    tl.to(searchPopup, {x: '-100%', duration: 0});
  })


  const menuLinks = document.querySelector('.popup-submenu.popup-submenu--mobile')
  const submenu = document.querySelector('.popup-submenu.popup-submenu--desktop')
  if (menuLinks && submenu) submenu.innerHTML = menuLinks.innerHTML



})
