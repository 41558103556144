import Swiper, {Navigation} from 'swiper';
import 'swiper/swiper.min.css';
import {gsap} from 'gsap'

document.addEventListener('DOMContentLoaded', () => {
  const mainSlider = document.querySelector('.main-slider')
  if (mainSlider) {
    new Swiper(mainSlider, {
      modules: [Navigation],
      navigation: {
        prevEl: '.main-slider__arrow--prev',
        nextEl: '.main-slider__arrow--next',
      },
      breakpoints: {
        1700: {
          slidesPerView: mainSlider.classList.contains('about-us__slider') ? 4.45 : 4,
          spaceBetween: 50,
        },
        1280: {
          slidesPerView: mainSlider.classList.contains('about-us__slider') ? 4 : 3,
          spaceBetween: mainSlider.classList.contains('about-us__slider') ? 27 : 40,
        },
        780: {
          slidesPerView: 3,
          spaceBetween:  13
        },
        0: {
          slidesPerView: 1,
          spaceBetween: 13,
        }
      },

    });
  }

  const newsSliderBlock = document.querySelector('.main-news__slider')
  let newsSlider;
  if (newsSliderBlock) {

    newsSlider = new Swiper(newsSliderBlock, {
      init: true,
      modules: [Navigation],
      navigation: {
        prevEl: '.main-slider__arrow--prev',
        nextEl: '.main-slider__arrow--next',
      },
      breakpoints: {
        1700: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        780: {
          slidesPerView: 3,
          spaceBetween: 27
        },
        0: {
          slidesPerView: 1,
          spaceBetween: 15,
        }
      },
    });
  }


  const photoSwiperSlides = document.querySelector('.photo-swiper')?.querySelectorAll('.photo-swiper__slide')
  const photoThumbs = document.querySelector('.photo-thumbs')
  if (photoThumbs && photoSwiperSlides.length) {
    let activeIndex = 0;
    let animationProcessing = false
    const startAnimation = (index) => {
      if (index >= 0 && index < photoSwiperSlides.length && index !== activeIndex && !animationProcessing) {
        animationProcessing = true
        photoSwiperSlides[index].classList.add(`photo-swiper__slide--animate${index < activeIndex ? '-to-right' : '-to-left'}`)
        photoSwiperSlides[index].classList.add('photo-swiper__slide--animate-in')
        photoSwiperSlides[activeIndex].classList.add('photo-swiper__slide--animate-out')
        photoSwiperSlides[activeIndex].classList.remove('photo-swiper__slide--active')
        setTimeout(() => {
          photoSwiperSlides[index].classList.remove(`photo-swiper__slide--animate${index < activeIndex ? '-to-right' : '-to-left'}`)
          photoSwiperSlides[index].classList.remove('photo-swiper__slide--animate-in')
          photoSwiperSlides[activeIndex].classList.remove('photo-swiper__slide--animate-out')
          photoSwiperSlides[index].classList.add('photo-swiper__slide--active')
          activeIndex = index
          animationProcessing = false
        }, 500)
      }
    }
    const photoThumbsSlider = new Swiper(photoThumbs, {

      breakpoints: {
        780: {
          slidesPerView: 6,
        },
        0: {
          slidesPerView: 3,
        }
      },

    });
    photoThumbsSlider.on('click', () => {
      startAnimation(photoThumbsSlider.clickedIndex)
    })
    const arrowPrev = document.querySelector('.photo-slider__arrow--prev')
    const arrowNext = document.querySelector('.photo-slider__arrow--next')
    arrowPrev?.addEventListener('click', () => {
      photoThumbsSlider.slideTo(activeIndex - 1)
      startAnimation(activeIndex - 1)
    })
    arrowNext?.addEventListener('click', () => {
      photoThumbsSlider.slideTo(activeIndex + 1)
      startAnimation(activeIndex + 1)
    })
  }


})

